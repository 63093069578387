import React, { useCallback, useState } from 'react';
import { Divider } from '../divider';
import clsx from 'clsx';
import { Link, useLocation } from '@remix-run/react';
import { pages } from '../../pages/pages-all';
import { PageHierarchyItem, PageHierarchyInformation } from '../../pages/pages-types';
import { useDisableBodyScroll } from '../../hooks/use-disable-body-scroll';
import Sprite from '../../sprites/sprite';
import { useSnapshot } from 'valtio';
import { mobileNav } from '../../state/mobile-nav';
import { useMessages } from '../../i18n/useMessages';

export const Navigation: React.FC<{
  textColor: 'light' | 'dark';
  isClearBackground?: boolean;
  headerAppearance?: 'subtle' | 'normal';
}> = ({ textColor, isClearBackground = false, headerAppearance }) => {
  return (
    <>
      <div className="md:hidden z-30">
        <MobileNavigation textColor={textColor} headerAppearance={headerAppearance} />
      </div>
      <div className="hidden md:flex md:justify-center z-30">
        <DesktopNavigation
          textColor={textColor}
          isClearBackground={isClearBackground}
          headerAppearance={headerAppearance}
        />
      </div>
    </>
  );
};

const DesktopNavigation: React.FC<{
  textColor: 'light' | 'dark';
  isClearBackground?: boolean;
  headerAppearance?: 'subtle' | 'normal';
}> = ({ textColor, isClearBackground = false, headerAppearance }) => {
  return (
    <nav
      className={clsx(
        'flex justify-center z-30 flex-row flex-wrap sm:flex-nowrap gap-3 md:gap-10 uppercase font-sans tracking-widest',
        {
          '-mt-4': headerAppearance === 'normal',
        },
      )}
    >
      <ul className="flex justify-center flex-row flex-wrap sm:flex-nowrap gap-3 md:gap-10 uppercase font-sans tracking-widest">
        {pages.map((route, key) => (
          <NavigationItem
            {...route}
            key={key}
            textColor={textColor}
            isClearBackground={isClearBackground}
          />
        ))}
      </ul>
    </nav>
  );
};

const MobileNavigation: React.FC<{
  textColor: 'light' | 'dark';
  headerAppearance?: 'subtle' | 'normal';
}> = ({ textColor, headerAppearance = 'normal' }) => {
  const isOpen = useSnapshot(mobileNav).isOpen;

  const onClickOpen = useCallback(() => (mobileNav.isOpen = true), []);

  useDisableBodyScroll(isOpen);

  const isDark = textColor === 'dark';
  const menuIcon = isDark ? 'menu-dark' : 'menu';

  return (
    <>
      {!isOpen && (
        <button
          onClick={onClickOpen}
          className={clsx(
            'w-12 h-12 rounded-full border-2 flex flex-col items-center justify-center',
            {
              'border-white': !isDark,
              'border-gray-300': isDark,
              'mr-8': headerAppearance === 'normal',
            },
          )}
        >
          <Sprite name={menuIcon} width="24" height="16" />
        </button>
      )}
    </>
  );
};

const NavigationItem: React.FC<
  PageHierarchyItem & { textColor: 'light' | 'dark'; isClearBackground?: boolean }
> = ({ title, path, children, textColor, isClearBackground = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onPointerOver = useCallback(() => setIsOpen(true), []);
  const onPointerLeave = useCallback(() => setIsOpen(false), []);

  const isDark = textColor === 'dark';

  const pathname = useLocation().pathname;
  const isSelected = pathname.split('/')[1] === path.split('/')[1];

  const getMessage = useMessages();
  const formattedTitle = getMessage(title);

  return (
    <li
      className="relative pb-2 m-0"
      onPointerOver={onPointerOver}
      onPointerLeave={onPointerLeave}
    >
      <Link
        prefetch="intent"
        to={path}
        className={clsx(
          'text-xs md:text-sm xl:text-base z-10 font-medium transition-all duration-300 ease-in-out',
          {
            'text-white': !isDark,
            'text-black': isDark,
            'text-yellow-250 underline underline-offset-4':
              isSelected && !isClearBackground,
            'text-orange-500 underline underline-offset-4':
              isSelected && isClearBackground,
            'hover:text-yellow-250': !isClearBackground,
            'hover:text-orange-500': isClearBackground,
          },
        )}
      >
        {formattedTitle}
      </Link>
      {children && children.length > 0 && isOpen ? (
        <NavigationDropdown routes={children} />
      ) : null}
    </li>
  );
};

interface NavigationDropdownProps {
  routes: PageHierarchyInformation[];
}

const NavigationDropdown: React.FC<NavigationDropdownProps> = ({ routes }) => {
  const pathname = useLocation().pathname;
  const getMessage = useMessages();

  return (
    <ul className="absolute min-w-56 md:min-w-64 mt-2 bg-white rounded-md p-4 md:p-6 transition-opacity ease-in animate-[0.3s_fadeIn] shadow-lg">
      {routes.map(({ title, path }, index) => {
        const isSelected = pathname === path;
        const formattedTitle = getMessage(title);

        return (
          <li key={index}>
            <Link
              prefetch="intent"
              to={path}
              className={clsx(
                'cursor-pointer text-black tracking-normal normal-case text-sm md:text-base hover:font-bold transition',
                {
                  'font-bold': isSelected,
                  'font-normal': !isSelected,
                },
              )}
            >
              {formattedTitle}
            </Link>
            {index !== routes.length - 1 ? (
              <Divider className="border-dashed border-gray-300 w-full my-3" />
            ) : null}
          </li>
        );
      })}
    </ul>
  );
};
